










































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import { api_store, store } from "@/http/store";
import PageHeader from "@/components/pageHeader/index.vue";
import { ElForm } from "element-ui/types/form";
import Upimg from "@/components/upimg/index.vue";
import Map from "@/components/map/index.vue";
import { api_user, user } from "@/http/user";
import {
  provinceAndCityData,
  pcTextArr,
  regionData,
  pcaTextArr,
  codeToText,
} from "element-china-area-data";
@Component({
  components: {
    PageHeader,
    Upimg,
    Map,
  },
})
export default class extends Vue {
  info: store = {
    id: "",
    name: "",
    cover: "",
    // province:codeToText(this.pcaTextArr[0]),
    // city: codeToText(this.pcaTextArr[1]),
    // region: codeToText(this.pcaTextArr[2]),
    province: "",
    city: "",
    region: "",
    address: "",
    phone: "",
    device: "",
    user: 1,
    status: 1,
    longitude: "",
    latitude: "",
    number: 1,
    create_time: "",
    update_time: "",
    wx_amount: 1,
    dy_amount: 1,
    account: "",
    password: "",
    user_level: 1,
  };
  coverUrls: string[] = [];
  pcaTextArr = pcaTextArr;
  selectedOptions: string[] = [];
  baseRules = {
    name: [{ required: true, message: "请输入" }],
    cover: [{ required: true, message: "请上传封面" }],
    // regionData: [{ required: true, message: "请选择省市" }],
    address: [{ required: true, message: "请填写具体位置" }],
    phone: [{ required: true, message: "请填写联系电话" }],
    device: [{ required: true, message: "请填写设备编号" }],
    number: [{ required: true, message: "请填写设备格子数" }],
    wx_amount: [{ required: true, message: "请填写微信分佣金额" }],
    dy_amount: [{ required: true, message: "请填写抖音分佣金额" }],
    account: [
      { required: true, message: "请填写门店后台账号" },
      {
        validator: (rule:any, value:any, callback:any) => {
          const phoneNumberRegex = /^1[3-9]\d{9}$/;
          if (!value || phoneNumberRegex.test(value)) {
            callback();
          } else {
            callback(new Error("手机号格式不正确"));
          }
        },
        trigger: "blur",
      },
    ],
    password: [{ required: true, message: "请填写门店后台密码" }],
  };
  generateRules(isEdit: boolean) {
    const rulesCopy = { ...this.baseRules }; // 创建 baseRules 的浅拷贝
    console.log(rulesCopy);
    if (isEdit) {
      // 在编辑模式下，清除 password 字段的验证规则
      (rulesCopy as { password?: any }).password = undefined;
      delete (rulesCopy as { password?: any }).password;
    }

    return rulesCopy;
  }

  //选择的省市区数据
  addressChange() {
    this.info.province = this.selectedOptions[0];
    this.info.city = this.selectedOptions[1];
    this.info.region = this.selectedOptions[2];
  }
  // 地图数据
  key = false;
  sendData({ name, gpsX, gpsY }: any) {
    this.info.longitude = gpsX;
    this.info.latitude = gpsY;
    this.key = false;
  }
  async submit() {
    await (this.$refs["form"] as ElForm | undefined)?.validate();
    if (this.id) {
      await api_store.edit(this.info);
    } else {
      await api_store.create(this.info);
    }
    // this.$message.success("提交成功");
    this.$router.go(-1);
  }

  async get_info() {
    this.info = await api_store.get_info(this.id as number);
    // 处理封面图
    if (typeof this.info.cover === "string") {
      const domain = "https://images.xiyuebiaoju.com/";
      this.coverUrls = this.info.cover
        .split(",")
        .map((url) => domain + url.trim());
    }

    // 处理三级联动
    const { province, city, region } = this.info;
    this.selectedOptions = [province, city, region];
  }
  // 监听图片数据
  @Watch("coverUrls")
  setCover(val: string[]) {
    const domain = "https://images.xiyuebiaoju.com/";
    this.info.cover = val.map((url) => url.replace(domain, "")).join(", ");
  }
  get id(): undefined | number {
    return this.$route.query.id as any;
  }
  // 获取用户列表
  userList: any[] = [];

  async get_user_list() {
    const res = await api_user.get_list({ page_size: 10000 });
    const list: any[] = [];
    // res.results.forEach((item:any)=>{
    //     item.value = item.city
    // })
    this.userList = list.concat(res.results);
  }

  created() {
    this.get_user_list();
    if (this.id === undefined) return;
    this.get_info();
  }
}
