
import { Http } from "@/http"
import { Id, res_list, req_list } from '@/types/global'


export interface add_user{
    id:Id,
    phone:string
    wallet:string
    nickname:string,
    avatar_url:string,
    store:string,
    user_level:string
}
export interface user extends add_user{
    superior:string
    superior_name:string
    create_time_after:string
    create_time_before:string
    user_type:string,
    status:string
    
}

export interface type{

}


class HttpUser extends Http{
	get_list = (data:req_list) => {
		return this.get<res_list<user>>({...data})
	}

    get_info = (id:number) => {
        return this.get<user>({},`/admin/user/${id}/`)
    }

    edit = ({id,...data}: any) => {
        return this.patch(data,`/admin/user/${id}/`)
    }
    create = (data:add_user) => {
        return this.post(data)
    }
    get_house_type_list = (data:req_list) => {
        return this.get<res_list<type>>(data,`/admin/housetypelist/`)
    }

    add_service = (data:{user:number, admin:number}) => {
        return this.post(data,`/admin/user/server/`)
    }

    add_house_type = (data:{user:number, housetype:string}) => {
        return this.post(data,`/admin/user/house/`)
    }

    set_status = (data:{id:number,status:number}) => {
        return this.post(data,`/admin/user/progress/`)
    }

    add_follow = (data:{user:number,content:string}) => {
        return this.post(data,`/admin/user/follow/`)
    }
    
}

export const api_user =  new HttpUser('/admin/user/')
